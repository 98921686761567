import { mapState, mapGetters, mapActions } from 'vuex'

// set your computed methods by mapping the state with data and setting our getters to call api;
export const pagecontentComputed = {
    ...mapState('pagecontent', {
        pagecontent:(state) => state.page_contents,
    }),
    ...mapGetters('pagecontent', ['getPageContent'])
}

// export property actions
export const pagecontentMethods = mapActions('pagecontent', ['allPageContent','createPageContent',
 'updatePageContent', 'deletePageContent', 'showPrivacyPolicyContent', 'showTermsOfServiceContent',
])
